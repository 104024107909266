import React from "react"
import { Content } from "components/content/Content"
import { Image } from "components/image/Image"
import { PriceTier } from "./PriceTier"
import { ImageBuilder } from "_types/AssetsImage"
import { ButtonBuilder } from "_types/Button"
import { RichTextContent } from "_types/RichText"
import { SinglePriceTier } from "./price-tier-types"
import "./single-service.module.scss"
import { ButtonParser } from "components/button/ButtonParser"

interface SingleServiceProps {
  name?: string
  serviceFeatures?: RichTextContent
  ctaButton?: ButtonBuilder[]
  pestsCovered?: RichTextContent
  priceTerm?: string
  priceTiers?: SinglePriceTier
  featuredImage?: ImageBuilder
  servicePosition?: number
  isTabbedContent?: boolean
  isAltBlock?: boolean
  mapRuns?: number
  disclaimer?: string
}

export const SingleService = ({
  name,
  serviceFeatures,
  ctaButton,
  pestsCovered,
  priceTerm,
  priceTiers,
  featuredImage,
  servicePosition,
  isTabbedContent = false,
  isAltBlock = false,
  mapRuns,
  disclaimer,
}: SingleServiceProps) => {
  const altBg = servicePosition && servicePosition % 2 ? false : true

  let oddOrEven = ""

  if (!isTabbedContent && isAltBlock && mapRuns) {
    oddOrEven = mapRuns % 2 ? "even" : "odd"
  }

  const alternatingClassName = isAltBlock
    ? `single-service__pricing-group--${oddOrEven}`
    : `single-service__pricing-group--even`

  const alternatingListClassName = isAltBlock
    ? `single-service__content-group--${oddOrEven}`
    : `single-service__content-group--even`

  return (
    <section
      className={`u-content-block-padding single-service ${
        altBg ? "alternate-background-color" : ""
      } ${!isTabbedContent ? "u-content-block-box" : ""}`}
    >
      <div className="fr-container single-service__inner u-clearfix">
        {name && (
          <div className="u-container-header">
            <h2 className="single-service__title">{name}</h2>
          </div>
        )}

        <div className={`single-service__body`}>
          <div
            className={`single-service__pricing-group ${alternatingClassName}`}
          >
            {featuredImage && (
              <Image
                image={featuredImage}
                width={featuredImage.width}
                height={featuredImage.height}
                className="single-service__image"
                objectPosition="center"
              />
            )}
            {priceTiers && (
              <PriceTier
                priceTier={priceTiers.elements}
                priceTerm={priceTerm || "monthly"}
              />
            )}
          </div>
          <div
            className={`single-service__content-group ${alternatingListClassName}`}
          >
            {serviceFeatures && serviceFeatures.value !== "<p><br></p>" && (
              <div className="single-service__content">
                <Content content={serviceFeatures} />
              </div>
            )}
            {pestsCovered && pestsCovered.value !== "<p><br></p>" && (
              <div className="single-service__coverage">
                <Content content={pestsCovered} />
              </div>
            )}
            {disclaimer && (
              <p className="single-service__disclaimer">{disclaimer}</p>
            )}
          </div>
        </div>

        {ctaButton && (
          <div className="single-service__buttons">
            <ButtonParser buttons={ctaButton} />
          </div>
        )}
      </div>
    </section>
  )
}
