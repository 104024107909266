import React from "react"
import { TextAndImageContentBlock } from "components/content-blocks/TextAndImageContentBlock"
import { useTabbedContainer } from "contexts/components/TabbedContainerContext"
import {
  ContentBlockElements,
  TabContentBlockItem,
} from "./tabbed-content-block-types"
import { SingleService } from "components/service-comparison/SingleService"
import { ServiceElements } from "components/service-comparison/service-types"

interface TabbedContentProps {
  isCBTypeTextWrapsImage?: boolean
}

export const TabbedContent = ({
  isCBTypeTextWrapsImage = false,
}: TabbedContentProps) => {
  const { activeItem } = useTabbedContainer<TabContentBlockItem>()

  const ariaLabelledBy = `tab-${activeItem.elements?.tab_title?.value.replaceAll(
    " ",
    "_"
  )}-${activeItem.id}`
  const tabPanelId = `tabpanel-${activeItem.elements?.tab_title?.value.replaceAll(
    " ",
    "_"
  )}-${activeItem.id}`

  const activeItemElements =
    activeItem?.elements?.content_block?.modular_content[0]?.elements

  if (!activeItemElements) return null

  if (activeItemElements.hasOwnProperty("service_features")) {
    const {
      service_features,
      pests_covered,
      price_term,
      button,
      price_tiers,
      image,
      pricing_disclaimer,
    } = activeItemElements as ServiceElements
    return (
      <div
        role="tabpanel"
        aria-labelledby={ariaLabelledBy}
        id={tabPanelId}
        className="fr-tabbed-content-block__body-tab-content"
      >
        <SingleService
          serviceFeatures={service_features}
          pestsCovered={pests_covered}
          priceTerm={price_term?.value[0].codename}
          ctaButton={button?.value}
          priceTiers={price_tiers?.value[0]}
          featuredImage={image?.value[0]}
          disclaimer={pricing_disclaimer?.value}
          isTabbedContent={isCBTypeTextWrapsImage}
        />
      </div>
    )
  }

  const { content, buttons, image, style_lists_as_grid } =
    activeItemElements as ContentBlockElements

  return (
    <div
      role="tabpanel"
      aria-labelledby={ariaLabelledBy}
      id={tabPanelId}
      className="fr-tabbed-content-block__body-tab-content"
    >
      <TextAndImageContentBlock
        content={content}
        buttons={buttons?.value}
        image={image?.value[0]}
        styleListsAsGrid={style_lists_as_grid?.value[0]?.codename === "yes"}
        hasBlockPadding={false}
        hasContainerClassName={false}
        contentBlockType="text_wraps_image"
      />
    </div>
  )
}
