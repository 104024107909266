import React from "react"

interface FAQPageWrapperProps {
  children: React.ReactNode
  isFAQPage: boolean
}

export const FAQPageWrapper = ({
  children,
  isFAQPage,
}: FAQPageWrapperProps) => {
  if (isFAQPage) {
    return (
      <article
        itemScope
        itemType="https://schema.org/FAQPage"
        className="page-content-above-parallax-bg-image"
      >
        <>{children}</>
      </article>
    )
  }
  return (
    <article className="page-content-above-parallax-bg-image">
      {children}
    </article>
  )
}
