import React, { HTMLAttributes, useEffect, useRef } from "react"
import { Text } from "_types/Text"
import { useTabbedContainer } from "contexts/components/TabbedContainerContext"
import { GoTriangleDown, GoTriangleRight } from "react-icons/go"
import { useKeydownNavigation } from "hooks/keyboardEvents/useKeydownNavigation"

type Tab = {
  id: string
  elements: {
    tab_title: Text
    svg_icon?: Text
  }
}

type DivWithoutClassName = Omit<HTMLAttributes<HTMLDivElement>, "className">

interface TabsProps extends DivWithoutClassName {
  tabbedContent?: any
  selectedServiceIndex?: number
  setSelectedService?: (selectedServiceId: number) => void
  className?: string
  showTabTitleIcon?: boolean
  hasSliderIndicatorBar?: boolean
  sliderBarDirection?: "horizontal" | "vertical"
}

export const Tabs = ({
  className = "fr-tabs-controller",
  showTabTitleIcon = false,
  hasSliderIndicatorBar = false,
  sliderBarDirection = "horizontal",
  ...rest
}: TabsProps) => {
  const tabListRef = useRef<HTMLDivElement>(null)
  const { activeTabIndex, handleChangeActiveTab, allContent } =
    useTabbedContainer<Tab>()

  const tabThemeIcon =
    sliderBarDirection === "vertical" ? (
      <GoTriangleRight size={30} />
    ) : (
      <GoTriangleDown size={30} />
    )

  const sliderIndicatorStyles =
    sliderBarDirection === "vertical"
      ? {
          transform: `translateY(calc(100%/${allContent.length}*${activeTabIndex}))`,
        }
      : {
          width: `calc(100%/${allContent.length})`,
          transform: `translateX(calc(100%*${activeTabIndex}))`,
        }

  useEffect(() => {
    if (!tabListRef.current) return

    const tabsNodes = tabListRef.current.querySelectorAll("[role=tab]") as
      | NodeListOf<HTMLButtonElement>
      | undefined

    if (!tabsNodes) return

    const tabsList = Array.from(tabsNodes)

    const tabsKeyboardEvent = useKeydownNavigation({ tabs: tabsList })

    tabsNodes.forEach(tab => {
      tab.addEventListener("keydown", tabsKeyboardEvent.onKeydown)
    })
  }, [tabListRef])

  return (
    <div className={className}>
      <div
        ref={tabListRef}
        role="tablist"
        className={`${className}__tabs-wrapper`}
        {...rest}
      >
        {allContent.map(({ id, elements }, index) => {
          const tabTitle = elements.tab_title.value
          const inlineSVGIcon = elements?.svg_icon?.value
          const ariaControls = `tabpanel-${tabTitle.replaceAll(" ", "_")}-${id}`
          return (
            <button
              onClick={() => handleChangeActiveTab(index)}
              key={id}
              className={`${className}__tab${
                activeTabIndex === index ? ` ${className}__tab--active` : ""
              }`}
              id={`tab-${tabTitle.replaceAll(" ", "_")}-${id}`}
              role="tab"
              aria-selected={activeTabIndex === index}
              aria-controls={ariaControls}
              tabIndex={activeTabIndex === index ? 0 : -1}
            >
              {showTabTitleIcon && inlineSVGIcon && (
                <span
                  className={`${className}__tab-icon`}
                  contentEditable="false"
                  dangerouslySetInnerHTML={{ __html: inlineSVGIcon }}
                />
              )}
              {tabTitle}
              <span className={`${className}__theme-tab-icon`}>
                {tabThemeIcon}
              </span>
            </button>
          )
        })}
      </div>
      {hasSliderIndicatorBar && (
        <div
          className={`${className}__tabs-bottom-slider`}
          style={sliderIndicatorStyles}
        >
          <div className={`${className}__tabs-indicator`}></div>
        </div>
      )}
    </div>
  )
}
