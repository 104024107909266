import React from "react"
import { graphql } from "gatsby"
import { NavigationTile } from "./NavigationTile"
import { SingleNagivationTile } from "./navigation-tile-type"
import { useLayout } from "contexts/components/LayoutContext"
import { SimpleTile } from "components/simple-tile/SimpleTile"
import { TileElements } from "components/simple-tile/simple-tile-types"

interface NavigationTilesProps {
  eyebrowText?: string
  heading?: string
  subHeading?: string
  navTiles: SingleNagivationTile[]
  hasAltBackgroundColor: boolean
}

export const NavigationTiles = ({
  navTiles,
  hasAltBackgroundColor,
  eyebrowText,
  heading,
  subHeading,
}: NavigationTilesProps) => {
  // Navigation tiles are a specific type of content block that is returned by Content blocks.
  const alternateBackground = hasAltBackgroundColor
    ? "alternate-background-color"
    : ""
  const { isHomepage } = useLayout()

  return (
    <section
      className={`u-content-block-padding nav-tile-container ${alternateBackground} u-content-block-box`}
    >
      <div className={`fr-container`}>
        {heading && (
          <div className={`u-container-header`}>
            {eyebrowText && <h4 className={`eyebrow-text`}>{eyebrowText}</h4>}
            {<h2>{heading}</h2>}
          </div>
        )}
        {subHeading && (
          <p className="nav-tile-container__introductory-paragraph">
            {subHeading}
          </p>
        )}
        <div className={`nav-tile-container__wrapper`}>
          {navTiles.map(({ id, elements }) => {
            if (!elements) return
            if (!isHomepage) {
              const formattedTile = {
                slug_generator: elements.heading,
                seo_metadata__rich_data_image: elements.background_image,
                slug: elements.internal_link.value[0].elements.slug,
                seo_metadata__overlay_cta_text: elements.cta,
              } as TileElements
              return <SimpleTile key={id} tile={formattedTile} />
            }
            return <NavigationTile key={id} tile={elements} />
          })}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment NavigationTilesFragment on kontent_item_navigation_block {
    id
    elements {
      heading {
        value
      }
      sub_heading {
        value
      }
      alt_background_color {
        value {
          codename
        }
      }
      navigation_tiles {
        modular_content {
          ...NavigationTileFragment
        }
      }
    }
  }
`
