import { graphql } from "gatsby";
import React, { ReactNode } from "react";

interface FAQGroupBannerProps {
  heading?: string;
  sub_heading?: string;
  hasAltBackgroundColor?: boolean;
  children: ReactNode;
}

export const FAQGroupBanner = ({
  heading,
  sub_heading,
  hasAltBackgroundColor = false,
  children,
}: FAQGroupBannerProps) => {
  const altBgColor = hasAltBackgroundColor ? " alternate-background-color" : "";

  return (
    <section className={`fr-group-banner u-content-block-padding${altBgColor}`}>
      <div className="fr-container">
        {(heading || sub_heading) && (
          <div className="u-container-header">
            {heading && <h2>{heading}</h2>}
            {sub_heading && <h3>{sub_heading}</h3>}
          </div>
        )}
        {children}
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FAQGroupBannerFragment on kontent_item_faq_group_banner {
    system {
      type
    }
    id
    elements {
      faq_group {
        modular_content {
          ...FAQGroupBlockFragment
        }
      }
      heading {
        value
      }
      sub_heading {
        value
      }
      alt_background_color {
        value {
          codename
        }
      }
    }
  }
`;
