import React from "react"
import { graphql } from "gatsby"
import { FAQ } from "./SingleFAQ/FAQ"
import { FAQQuestion } from "./SingleFAQ/FAQQuestion"
import { FAQAnswer } from "./SingleFAQ/FAQAnswer"
import { Content } from "../content/Content"
import { FAQs } from "./faq-types"
import "./faq.module.scss"
import { Accordion } from "../accordion/Accordion"
import { AccordionItem } from "../accordion/AccordionItem"
import { AccordionToggle } from "../accordion/AccordionToggle"
import { AccordionPanel } from "../accordion/AccordionPanel"
import { Image } from "../image/Image"

interface FAQGroupBlockProps {
  faqs: FAQs[]
  isCollapsable?: boolean
}

export const FAQGroupBlock = ({
  faqs,
  isCollapsable = false,
}: FAQGroupBlockProps) => {
  const getCollapsableFAQGroup = (isCollapsable: boolean) => {
    if (isCollapsable) {
      return (
        <Accordion isFAQ firstActivePanel={faqs[0].id}>
          {faqs.map(({ elements, id }) => {
            const { answer, question, image } = elements
            return (
              <AccordionItem key={id} id={id}>
                <AccordionToggle>{question.value}</AccordionToggle>
                <AccordionPanel>
                  <Content content={answer} />
                  <>
                    {image?.value[0] && (
                      <Image
                        image={image.value[0]}
                        className="fr-faq-group-block__image"
                        alt={image.value[0]?.description}
                        width={image.value[0]?.width}
                      />
                    )}
                  </>
                </AccordionPanel>
              </AccordionItem>
            )
          })}
        </Accordion>
      )
    }
  }

  return (
    <div className="fr-faq-group-block">
      {!isCollapsable &&
        faqs.map(({ elements, id }) => {
          const { question, answer, image } = elements
          return (
            <FAQ key={id} image={image?.value[0]}>
              <FAQQuestion>{question.value}</FAQQuestion>
              <FAQAnswer>
                <Content content={answer} />
              </FAQAnswer>
            </FAQ>
          )
        })}

      {getCollapsableFAQGroup(isCollapsable)}
    </div>
  )
}

export const queryFAQGroupBlock = graphql`
  fragment FAQGroupBlockFragment on kontent_item_faq_group_block {
    id
    elements {
      faqs {
        modular_content {
          ...FAQFragment
        }
      }
      is_faq_item_collapsable {
        value {
          codename
        }
      }
    }
  }
`
