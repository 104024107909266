import React from "react"
import { graphql } from "gatsby"
import { Content } from "components/content/Content"
import { Coupon } from "./Coupon"
import { RichTextContent } from "_types/RichText"
import { CouponType } from "./coupons"
import "./coupons.module.scss"
import { ButtonBuilder } from "_types/Button"
import { ButtonParser } from "components/button/ButtonParser"

interface CouponBannerProps {
  heading?: string
  subHeading?: string
  eyebrowText?: string
  description?: RichTextContent
  button?: ButtonBuilder[]
  coupons: CouponType[]
  hasAltBackgroundColor: boolean
}

const COUPON_CLASSNAME: { [key: number]: string } = {
  1: "coupon-banner__grid--1",
  2: "coupon-banner__grid--2",
  3: "coupon-banner__grid--3",
}
export const CouponBanner = ({
  heading,
  subHeading,
  eyebrowText,
  description,
  coupons,
  button,
  hasAltBackgroundColor,
}: CouponBannerProps) => {
  const isContent =
    eyebrowText || heading || subHeading || !!button?.length ? true : false
  return (
    <section
      className={
        hasAltBackgroundColor
          ? "u-content-block-padding u-content-block-box alternate-background-color"
          : "u-content-block-padding"
      }
    >
      <div className="fr-container">
        {isContent && (
          <div className={"u-container-header"}>
            {eyebrowText && <h4 className="eyebrow-text">{eyebrowText}</h4>}
            {heading && <h2>{heading}</h2>}
            {subHeading && <h3>{subHeading}</h3>}
          </div>
        )}
        <div
          className={`${COUPON_CLASSNAME[coupons.length]} ${
            description?.value === "<p><br></p>" &&
            `no-description-${coupons.length}`
          }`}
        >
        {((description && description.value !== "<p><br></p>") || !!button?.length) && (
          <div className="coupon-banner__info">
            {description && description.value !== "<p><br></p>" && (
              <Content content={description} />
            )}

            {!!button?.length && (
              <ButtonParser
                buttons={button}
                extraClassNames="coupon-banner__button"
              />
            )}
          </div>
        )}
          {coupons.map(({ elements, id }) => {
            const {
              coupon_value,
              sub_heading,
              description,
              offer_end_date,
              disclaimer,
              button,
              hide_logo,
            } = elements

            return (
              <Coupon
                key={id}
                hasAltBackgroundColor={hasAltBackgroundColor}
                couponValue={coupon_value?.value}
                subHeading={sub_heading?.value}
                description={description}
                disclaimer={disclaimer?.value}
                buttons={button?.value}
                endDate={offer_end_date?.value}
                hideLogo={hide_logo?.value[0]?.codename === "yes"}
              />
            )
          })}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment CouponBannerFragment on kontent_item_coupon_banner {
    id
    elements {
      heading {
        value
      }
      sub_heading {
        value
      }
      eyebrow_text {
        value
      }
      description {
        value
        links {
          link_id
          url_slug
        }
      }
      button {
        value {
          ...ButtonFragment
        }
      }
      coupons {
        modular_content {
          ...CouponFragment
        }
      }
      alt_background_color {
        value {
          codename
        }
      }
    }
  }
`
