import React from "react"
import { SimpleTile } from "./SimpleTile"
import "./simple-tile.module.scss"
import { Tile } from "./simple-tile-types"

interface SimpleTileListProps {
  tiles: Tile[]
  hasAltBackgroundColor?: boolean
}

export const SimpleTileList = ({
  tiles,
  hasAltBackgroundColor = false,
}: SimpleTileListProps) => {
  const alternateBackground = hasAltBackgroundColor
    ? "alternate-background-color"
    : ""

  return (
    <section
      className={`u-content-block-padding tile-grid ${alternateBackground}`}
    >
      <div className="fr-container tile-grid__wrapper">
        {tiles.map(({ elements, id }) => {
          if (!elements) return
          return <SimpleTile key={id} tile={elements} />
        })}
      </div>
    </section>
  )
}
