import React, { KeyboardEvent, useRef, useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Image } from "../image/Image"
import { SampleNextArrow, SamplePrevArrow } from "./SliderArrow"
import { SingleGalleryImage } from "./gallery-types"
import "./gallery-slider.module.scss"

interface GallerySliderProps {
  images: SingleGalleryImage[]
  firstSlideIndex: number
}

export const GallerySlider = ({
  images,
  firstSlideIndex,
}: GallerySliderProps) => {
  const slideIndex = useRef<number>(firstSlideIndex)
  const [nav1, setNav1] = useState<Slider>()
  const [currentIndex, setCurrentIndex] = useState(slideIndex.current)

  const handleNextSlide = () => {
    if (nav1) {
      nav1.slickNext()
    }
  }

  const handlePrevSlide = () => {
    if (nav1) {
      nav1.slickPrev()
    }
  }

  const keyAction = (e: KeyboardEvent) => {
    e.keyCode === 39 && handleNextSlide()
    e.keyCode === 37 && handlePrevSlide()
  }

  const setSlideIndex = (index: number) => {
    if (slideIndex) {
      slideIndex.current = index
    }
  }

  const settings = {
    className: "fr-gallery-slider__body",
    dots: true,
    initialSlide: slideIndex.current,
    customPaging: (i: number) => {
      return <span className="fr-gallery-slider__counter">{i + 1} of {images?.length}</span>;
    },
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    swipeToSlide: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    beforeChange: (current: number, next: any) => {
      if (next !== current) {
        setSlideIndex(next)
        setCurrentIndex(next)
      }
    },
  }

  return (
    <div className="fr-gallery-slider" onKeyDown={keyAction}>
      <div className="fr-gallery-slider__controller">
        <Slider {...settings}>
          {images.map(({ elements, id }) => {
            if (!elements) return
            const { single_image, caption } = elements

            return (
              <div className="fr-gallery-slider__slide" key={id}>
                <Image
                  image={single_image.value[0]}
                  alt={single_image.value[0]?.description ?? ""}
                  className="fr-gallery-slider__image"
                />
                <div
                  className="fr-gallery-slider__caption-container"
                  style={{ maxWidth: `${single_image.value[0]?.width}px` }}
                >
                  {caption?.value && (
                    <span className="fr-gallery-slider__caption">
                      {caption.value}
                    </span>
                  )}
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}
