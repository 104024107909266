import React from "react"
import { Link, graphql } from "gatsby"
import "./navigation-tile.module.scss"
import { Image } from "components/image/Image"
import { useLayout } from "contexts/components/LayoutContext"
import { NavigationTileElements } from "./navigation-tile-type"

interface NavigationTileProps {
  tile: NavigationTileElements
  showDescription?: boolean
}

export const NavigationTile = ({
  tile,
  showDescription = false,
}: NavigationTileProps) => {
  const { background_image, cta, heading, internal_link, description } = tile

  const slug = internal_link?.value[0]?.elements?.slug?.value
  const { linkedPagesUrlPathCodex } = useLayout()
  const path = linkedPagesUrlPathCodex[slug] || "404"
  const navSlug = path.startsWith("/") ? path : `/${path}`

  return (
    <Link to={navSlug} className={`nav-tile`}>
      {background_image.value[0] && (
        <div className="tile__body">
          <Image
            image={background_image.value[0]}
            alt={background_image.value[0].description}
            className={`nav-tile__image`}
            objectFit="cover"
          />
          <div className={`nav-tile__overlay`}>
            {heading?.value && (
              <h3 className="nav-tile__heading">{heading.value}</h3>
            )}
            <p className={`nav-tile__overlay-cta`}>
              {cta.value || "Learn More"}
            </p>
          </div>
        </div>
      )}
      {showDescription && description?.value && (
        <p className={`nav-tile__description u-text--align-left`}>
          {description.value}
        </p>
      )}
    </Link>
  )
}

export const query = graphql`
  fragment NavigationTileFragment on kontent_item_navigation_tile {
    id
    elements {
      heading {
        value
      }
      description {
        value
      }
      cta {
        value
      }
      background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      svg_icon {
        value
      }
      internal_link {
        value {
          ... on kontent_item_page {
            id
            elements {
              slug {
                value
              }
            }
          }
        }
      }
    }
  }
`
