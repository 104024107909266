import React from 'react';
import { useAccordion, useAccordionItem } from 'contexts/components/AccordionContext';
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';
import './accordion.module.scss';

interface AccordionToggleProps {
	children: string
}

export const AccordionToggle = ({children}: AccordionToggleProps) => {
	const { handlePanelClick, isFAQ, activePanel } = useAccordion();
	const { id } = useAccordionItem();

	const isButtonActive = activePanel === id ? " fr-accordion__btn-toggle--active" : "";

	const faqName = isFAQ ? {
		itemProp: "name"
	} : {};

	return (
		<button 
			className={`fr-accordion__btn-toggle${isButtonActive}`}
			onClick={() => handlePanelClick(id)}
			type="button" 
		>
		<h3 {...faqName}>{children}</h3>
		{ (activePanel === id) ? (
			<GoTriangleUp size={ 30} style={{ marginLeft: '1.125rem' }} />
		) : (
			<GoTriangleDown size={ 30 } style={{ marginLeft: '1.125rem' }} />
		) }
		</button>
	)
}