import React from "react"
import { graphql } from "gatsby"
import { Service } from "./Service"
import { IService } from "./service-types"
import "./service.module.scss"
interface ServiceComparisonProps {
  heading?: string
  subHeading?: string
  hasAltBackgroundColor: boolean
  services: IService[]
  disclaimer?: string
}

export const ServiceComparison = ({
  heading,
  subHeading,
  hasAltBackgroundColor,
  services,
  disclaimer,
}: ServiceComparisonProps) => {
  return (
    <section
      className={
        hasAltBackgroundColor
          ? "service-comparison--alt u-content-block-padding u-content-block-box"
          : "service-comparison u-content-block-padding u-content-block-box"
      }
    >
      <div className="fr-container">
        {heading && (
          <div className="u-container-header">
            <h2>{heading}</h2>
            {subHeading && <h3>{subHeading}</h3>}
          </div>
        )}

        <div
          className={
            (services.length >= 3
              ? `service-comparison__grid service-comparison__grid--multiple`
              : "service-comparison__grid") +
            (disclaimer && ` service-comparison__grid--disclaimer`)
          }
        >
          {services.map(({ elements, id }, i) => (
            <Service key={id} index={i} serviceInfo={elements} />
          ))}
        </div>
        {disclaimer && (
          <p className="service-comparison__disclaimer">{disclaimer}</p>
        )}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment ServiceComparisonFragment on kontent_item_service_comparison {
    id
    elements {
      heading {
        value
      }
      sub_heading {
        value
      }
      alt_background_color {
        value {
          codename
        }
      }
      services {
        modular_content {
          ...ServiceFragment
        }
      }
      disclaimer {
        value
      }
    }
  }
`
