import React from "react"
import { Content } from "../content/Content"
import "./content-block.module.scss"
import { RichTextContent } from "_types/RichText"
import { ButtonBuilder } from "_types/Button"
import { ButtonParser } from "components/button/ButtonParser"

interface TextContentBlockProps {
  content?: RichTextContent
  heading?: string
  subHeading?: string
  eyebrowText?: string
  hasAltBackgroundColor?: boolean
  buttons?: ButtonBuilder[]
  styleListsAsGrid?: boolean
  customId: string
}

export const TextContentBlock = ({
  content,
  heading,
  eyebrowText,
  subHeading,
  hasAltBackgroundColor,
  buttons,
  styleListsAsGrid = false,
  customId,
}: TextContentBlockProps) => {
  const contentNotEmpty = content?.value === "<p><br></p>" ? false : true
  const alternateBackground = hasAltBackgroundColor
    ? "alternate-background-color"
    : ""

  const parentContainerClassName = `u-content-block-box`

  return (
    <section
      id={customId && customId.includes("#") ? customId.substring(1) : customId}
      className={`text-content-block u-content-block-padding ${parentContainerClassName} ${alternateBackground} u-clearfix`}
    >
      <div className="fr-container">
        <div className={`u-container-header text-content-block__title`}>
          {eyebrowText && <h4 className={`eyebrow-text`}>{eyebrowText}</h4>}
          {heading && <h2>{heading}</h2>}
          {subHeading && <h3>{subHeading}</h3>}
        </div>
        {content && contentNotEmpty && (
          <div className="text-content-block__content">
            <Content content={content} styleListsAsGrid={styleListsAsGrid} />
          </div>
        )}
        {buttons && buttons.length > 0 && (
          <div className="content-block-buttons">
            <ButtonParser
              buttons={buttons}
              extraClassNames="content-block__button"
            />
          </div>
        )}
      </div>
    </section>
  )
}
