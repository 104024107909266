import React from "react"
import "./service.module.scss"
import { graphql } from "gatsby"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { PriceTier } from "./PriceTier"
import { SinglePriceTier } from "./price-tier-types"

import { ServiceElements } from "./service-types"
import { ButtonParser } from "components/button/ButtonParser"
interface ServiceProps {
  flag?: string
  index: number
  price_term?: string
  price_tiers?: SinglePriceTier
  serviceInfo: ServiceElements
}

export const Service = ({ serviceInfo, index }: ServiceProps) => {
  const { button, flag, name, price_term, price_tiers, service_features } =
    serviceInfo

  return (
    <div
      className={"service" + (flag?.value && ` service--flag`)}
      data-flag={flag?.value}
    >
      {name?.value && (
        <div className="service__header">
          <h3>{name.value}</h3>
        </div>
      )}

      {service_features?.value && service_features?.value !== "<p><br></p>" && (
        <div className="service__body">
          <RichTextElement value={service_features.value} />
        </div>
      )}

      <div
        className={
          index === 1
            ? `service__footer service__footer--primary`
            : `service__footer`
        }
      >
        {price_tiers && price_tiers.value && (
          <PriceTier
            priceTerm={price_term?.value[0]?.codename || "monthly"}
            priceTier={price_tiers?.value[0]?.elements}
          />
        )}

        {button && button.value?.length > 0 && (
          <div className="content-block-buttons">
            <ButtonParser buttons={button.value} />
          </div>
        )}
      </div>
    </div>
  )
}

export const query = graphql`
  fragment ServiceFragment on kontent_item_service {
    system {
      type
    }
    id
    elements {
      flag {
        value
      }
      name {
        value
      }
      service_features {
        value
        links {
          link_id
          url_slug
        }
      }
      icon {
        value {
          ...KenticoAssetElementFragment
        }
      }
      image_gallery {
        modular_content {
          ...GalleryFragment
        }
      }
      pests_covered {
        value
        links {
          link_id
          url_slug
        }
      }
      price {
        value
      }
      price_term {
        value {
          codename
        }
      }
      pricing_disclaimer {
        value
      }
      button {
        value {
          ...AnchorLinkFragment
          ...ButtonFragment
        }
      }
      image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      price_tiers {
        value {
          ... on kontent_item_service_price_tier {
            id
            elements {
              ending_footage {
                value
              }
              footage_type {
                value {
                  codename
                }
              }
              initial_fee {
                value
              }
              price {
                value
              }
              starting_footage {
                value
              }
            }
          }
        }
      }
    }
  }
`
