import React from "react"
import { Link, graphql } from "gatsby"
import "./simple-tile.module.scss"
import { TileElements } from "./simple-tile-types"
import { Image } from "components/image/Image"
import { useLayout } from "contexts/components/LayoutContext"

interface SimpleTileProps {
  tile: TileElements
}

export const SimpleTile = ({ tile }: SimpleTileProps) => {
  const {
    slug_generator,
    seo_metadata__rich_data_image,
    slug,
    seo_metadata__overlay_cta_text,
  } = tile

  const { linkedPagesUrlPathCodex } = useLayout()
  const path = linkedPagesUrlPathCodex[slug?.value] || "404"
  const navSlug = path.startsWith("/") ? path : `/${path}`

  return (
    <Link to={navSlug} className="tile">
      {seo_metadata__rich_data_image?.value.length > 0 && (
        <div className="tile__body">
          <Image
            image={seo_metadata__rich_data_image.value[0]}
            alt={seo_metadata__rich_data_image.value[0].description}
            className="tile__image"
            objectFit="cover"
          />
          <div className={`tile__overlay`}>
            <h3 className="tile__heading">{slug_generator.value}</h3>
            <p className="tile__overlay-cta">
              {seo_metadata__overlay_cta_text?.value || "Learn More"}
            </p>
          </div>
        </div>
      )}
    </Link>
  )
}

export const query = graphql`
  fragment SimpleTileFragment on kontent_item_legacy_pest_library {
    id
    elements {
      pages {
        value {
          ... on kontent_item_page {
            id
            elements {
              slug {
                value
              }
              slug_generator {
                value
              }
              seo_metadata__rich_data_image {
                value {
                  url
                  width
                  height
                  description
                }
              }
              seo_metadata__overlay_cta_text {
                value
              }
            }
          }
        }
      }
    }
  }
`
