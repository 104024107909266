import React from "react"
import { graphql } from "gatsby"
import { Content } from "components/content/Content"
import { RichTextContent } from "_types/RichText"
import { ButtonBuilder } from "_types/Button"
import { useLogos } from "hooks/queries/settings/useLogos"
import { Image } from "components/image/Image"
import { ButtonParser } from "components/button/ButtonParser"

interface CouponProps {
  couponValue?: string
  subHeading?: string
  description?: RichTextContent
  buttons: ButtonBuilder[]
  disclaimer?: string
  endDate?: string
  hideLogo?: boolean
  hasAltBackgroundColor: boolean
}

export const Coupon = ({
  couponValue,
  subHeading,
  description,
  buttons,
  disclaimer,
  endDate,
  hideLogo = false,
  hasAltBackgroundColor = false,
}: CouponProps) => {
  const { footerLogo } = useLogos()
  return (
    <div
      className={`${hasAltBackgroundColor ? "coupon coupon--alt" : "coupon"}`}
    >
      <div className="coupon__header">
        {couponValue && <h2 className="coupon__heading">{couponValue}</h2>}
        {subHeading && <h3 className="coupon__sub-heading">{subHeading}</h3>}
        {description && description.value !== "<p><br></p>" && (
          <Content content={description} />
        )}
        {!!buttons.length && (
          <ButtonParser buttons={buttons} extraClassNames="coupon__button" />
        )}
        {endDate && <p className="coupon__end-date">Offer Ends: {endDate}</p>}
        {disclaimer && <p className="coupon__disclaimer">*{disclaimer}</p>}
      </div>
      {!hideLogo && (
        <div className="coupon__footer">
          <Image image={footerLogo} className="coupon__logo" />
        </div>
      )}
    </div>
  )
}

export const query = graphql`
  fragment CouponFragment on kontent_item_coupon {
    system {
      type
    }
    id
    elements {
      coupon_value {
        value
      }
      sub_heading {
        value
      }
      description {
        value
      }
      hide_logo {
        value {
          codename
        }
      }
      button {
        value {
          ...ButtonFragment
          ...AnchorLinkFragment
        }
      }
      offer_end_date {
        value
      }
      disclaimer {
        value
      }
    }
  }
`
