import React from "react"
import { useInView } from "react-intersection-observer"
import { SingleHighlight } from "../highlights-types"
import "./multi-content-highlights.module.scss"
import { Highlight } from "../Highlight"

interface MultiContentHighlightsProps {
  highlights: SingleHighlight[]
}

const MODIFIERS_BY_COUNT: Record<number, string> = {
  2: "mcb-highlights__wrapper--two-children",
  3: "mcb-highlights__wrapper--three-children",
  4: "mcb-highlights__wrapper--four-children",
}

export const MultiContentHighlights = ({
  highlights,
}: MultiContentHighlightsProps) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  })

  const getWrapperModifier = (arraySize: number) => {
    if (arraySize <= 1) return ""
    if (arraySize > 1 && arraySize <= 4) {
      return MODIFIERS_BY_COUNT[arraySize]
    }
    if (arraySize > 4) {
      return MODIFIERS_BY_COUNT[4]
    }
  }

  const mcbWrapperModifier = getWrapperModifier(highlights.length)

  return (
    <div className={`mcb-highlights__wrapper ${mcbWrapperModifier}`} ref={ref}>
      {highlights.map(({ elements, id }) => {
        const {
          heading,
          caption,
          svg_icon,
          image,
          counter_start_value,
          counter_end_value,
          animated_counter,
          special_character,
        } = elements
        return (
          <Highlight
            title={heading?.value}
            image={image?.value[0]}
            caption={caption}
            key={id}
            leftSvg={svg_icon?.value}
            counterStartValue={counter_start_value?.value}
            counterEndValue={counter_end_value?.value}
            isAnimated={animated_counter?.value[0]?.codename === "yes"}
            specialCharacter={special_character?.value[0]?.name}
            baseClassName="highlights"
            inView={inView}
          />
        )
      })}
    </div>
  )
}
