import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import "./highlights.module.scss"
import { ImageBuilder } from "_types/AssetsImage"
import { Image } from "components/image/Image"
import { Content } from "components/content/Content"
import { RichTextContent } from "_types/RichText"
import { HighlightIcon } from "./HighlightIcon"

interface HighlightProps {
  image?: ImageBuilder
  caption?: RichTextContent
  title?: string
  leftSvg?: string

  counterStartValue?: number
  counterEndValue?: number
  inView?: boolean
  isAnimated?: boolean
  specialCharacter?: string
  baseClassName: string
}

export const Highlight = ({
  image,
  caption,
  title,
  leftSvg,

  specialCharacter,
  baseClassName,
  counterStartValue = 0,
  counterEndValue = 0,
  inView = false,
  isAnimated = false,
}: HighlightProps) => {
  const [counter, setCounter] = useState(() =>
    isAnimated ? counterStartValue : counterEndValue
  )

  const fadeInSpecialCharacterClassModifier =
    counter === counterEndValue
      ? `${baseClassName}__special-character--fade-in`
      : ""

  useEffect(() => {
    if (!isAnimated) return
    if (!counterEndValue) return

    let mounted = true

    const countdown = (counterStartValue: number, counterEndValue: number) => {
      const count = counterStartValue
      const increment = counterEndValue / 250
      if (count === counterEndValue || count > counterEndValue) {
        setCounter(counterEndValue)
        return
      }
      const updatedCounter = Math.ceil(count + increment)
      setCounter(updatedCounter)
    }

    if (!inView) return

    if (counter === counterEndValue) return

    setTimeout(() => {
      if (mounted) {
        countdown(counter, counterEndValue)
      }
    }, 3)

    return () => {
      mounted = false
    }
  }, [counter, counterEndValue, inView, counterStartValue, isAnimated])

  return (
    <div className={`${baseClassName}__child`}>
      <div className={`${baseClassName}__icon-wrapper`}>
        {image && !leftSvg && !counterEndValue && (
          <Image
            alt={image.description}
            className={`${baseClassName}__img`}
            height={image.height}
            image={image}
            width={image.width}
            objectFit="contain"
            objectPosition="center"
          />
        )}
        {leftSvg && !counterEndValue && (
          <HighlightIcon
            leftSvg={leftSvg}
            className={`${baseClassName}__icon`}
          />
        )}
        {counterEndValue && (
          <div
            className={`${baseClassName}__row ${
              counterEndValue ? `${baseClassName}__row--counter` : ""
            }`}
          >
            {image && !leftSvg && (
              <Image
                alt={image.description}
                className={`${baseClassName}__img`}
                height={image.height}
                image={image}
                width={image.width}
                objectFit="contain"
                objectPosition="center"
              />
            )}
            {leftSvg && (
              <HighlightIcon
                leftSvg={leftSvg}
                className={`${baseClassName}__icon`}
              />
            )}
            <p
              className={`${baseClassName}__counter ${
                image || leftSvg ? `${baseClassName}__counter--flex-start` : ""
              }`}
            >
              {isAnimated && (
                <span
                  className={`${baseClassName}__counter--hidden-element-to-set-animated-counter-width`}
                  aria-hidden="true"
                >
                  {counterEndValue}
                  {specialCharacter && (
                    <span
                      className={`${baseClassName}__special-character ${fadeInSpecialCharacterClassModifier}`}
                    >
                      {specialCharacter}
                    </span>
                  )}
                </span>
              )}

              <span style={{ position: "absolute" }}>
                {counter}
                {specialCharacter && (
                  <span
                    className={`${baseClassName}__special-character ${fadeInSpecialCharacterClassModifier}`}
                  >
                    {specialCharacter}
                  </span>
                )}
              </span>
            </p>
          </div>
        )}
        {title && (
          <h3
            className={counterEndValue ? `${baseClassName}__counter-title` : ""}
          >
            {title}
          </h3>
        )}
      </div>
      {caption && caption.value !== "<p><br></p>" && (
        <div
          className={
            counterEndValue
              ? `${baseClassName}__text`
              : `${baseClassName}__caption`
          }
        >
          <Content content={caption} />
        </div>
      )}
    </div>
  )
}

export const query = graphql`
  fragment HighlightFragment on kontent_item_highlight {
    system {
      name
    }
    id
    elements {
      caption {
        value
        links {
          link_id
          url_slug
        }
      }
      image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      svg_icon {
        value
      }
      heading {
        value
      }
      counter_start_value {
        value
      }
      counter_end_value {
        value
      }
      special_character {
        value {
          name
        }
      }
      animated_counter {
        value {
          codename
        }
      }
    }
  }
`
