import React from "react"
import { graphql } from "gatsby"
import { TabbedContainer } from "components/tabs/TabbedContainer"
import { Tabs } from "components/tabs/Tabs"
import { TabbedContent } from "./TabbedContent"
import { TabContentBlockItem } from "./tabbed-content-block-types"
import "./tabbed-content-banner.module.scss"
import { TabbedContentSlider } from "./TabbedContentSlider"

interface TabbedContentBannerProps {
  heading?: string
  subHeading?: string
  content: TabContentBlockItem[]
  hasAltBackgroundColor: boolean
}

export const TabbedContentBlockBanner = ({
  heading,
  subHeading,
  content,
  hasAltBackgroundColor,
}: TabbedContentBannerProps) => {
  const alternateBackground = hasAltBackgroundColor
    ? "alternate-background-color"
    : ""

  const isAsideColumn = content?.length > 4 ? true : false
  const bodyModifier = content?.length > 4 ? "--row" : "--column"
  const tablistId = `tablist-${content.length}-${heading?.replaceAll(" ", "_")}`
  return (
    <div
      className={`fr-tabbed-content-block u-content-block-padding ${alternateBackground} u-content-block-box`}
    >
      <div className="fr-container">
        {heading && (
          <div className={`u-container-header`}>
            <h2 id={tablistId}>{heading}</h2>
            {subHeading && <h3>{subHeading}</h3>}
          </div>
        )}
        <div className={`fr-tabbed-content-block__body${bodyModifier}`}>
          {/* Laptop/Desktop */}
          <TabbedContainer allContent={content}>
            {isAsideColumn ? (
              <Tabs
                aria-labelledby={tablistId}
                sliderBarDirection="vertical"
                className="fr-cb-tabs-column"
              />
            ) : (
              <Tabs
                aria-labelledby={tablistId}
                showTabTitleIcon
                className="fr-cb-tabs-row"
              />
            )}

            <TabbedContent isCBTypeTextWrapsImage={content?.length > 4} />
          </TabbedContainer>

          {/* Tablet/Mobile */}
          <TabbedContentSlider
            aria-labelledby={tablistId}
            content={content}
            hasAltBackgroundColor={hasAltBackgroundColor}
          />
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment TabbedContentBannerFragment on kontent_item_tabbed_content_banner {
    id
    elements {
      heading {
        value
      }
      sub_heading {
        value
      }
      alt_background_color {
        value {
          codename
        }
      }
      tabbed_content {
        modular_content {
          ... on kontent_item_tab {
            id
            elements {
              tab_title {
                value
              }
              svg_icon {
                value
              }
              content_block {
                modular_content {
                  ...ContentBlockFragment
                  ...ServiceFragment
                }
              }
            }
          }
        }
      }
    }
  }
`
