import React from "react"
import { graphql } from "gatsby"
import { useInView } from "react-intersection-observer"
import { Highlight } from "./Highlight"
import { SingleHighlight } from "./highlights-types"
import "./highlights.module.scss"

interface HighlightsProps {
  eyebrowText?: string
  heading?: string
  subHeading?: string
  highlights: SingleHighlight[]
  hasAltBackgroundColor: boolean
}

export const Highlights = ({
  eyebrowText,
  heading,
  subHeading,
  highlights,
  hasAltBackgroundColor,
}: HighlightsProps) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  })

  const atLeastOneHasACounter = highlights.some(
    highlight => typeof highlight.elements.counter_end_value?.value === "number"
  )

  const isMoreThan3HighlightsWithCounter =
    highlights.length > 3 && atLeastOneHasACounter
  const isMoreThan4HighlightsWithoutCounter =
    highlights.length > 4 && !atLeastOneHasACounter

  const isHighDensity = isMoreThan3HighlightsWithCounter

  const isHighDensityWithoutCounter = isMoreThan4HighlightsWithoutCounter

  return (
    <section
      className={`highlights u-content-block-padding u-content-block-box ${
        hasAltBackgroundColor ? "alternate-background-color" : ""
      } `}
    >
      <div className="fr-container">
        {heading && (
          <div className="u-container-header">
            {eyebrowText && <h4 className={`eyebrow-text`}>{eyebrowText}</h4>}
            <h2>{heading}</h2>
            {subHeading && <h3>{subHeading}</h3>}
          </div>
        )}
        <div
          className={`highlights__wrapper
            ${
              highlights.length % 2
                ? "highlights__wrapper--odd"
                : "highlights__wrapper--even"
            }
            ${
              isHighDensity
                ? "highlights__wrapper--high-density"
                : ""
            }
            ${
              isHighDensityWithoutCounter
                ? "highlights__wrapper--high-density--no-counter"
                : ""
            }
            ${
              atLeastOneHasACounter
                ? "highlights__wrapper--child-with-border"
                : ""
            }
            `}
          ref={ref}
        >
          {highlights.map(({ elements, id }) => {
            const {
              heading,
              caption,
              svg_icon,
              image,
              counter_start_value,
              counter_end_value,
              animated_counter,
              special_character,
            } = elements
            return (
              <Highlight
                title={heading?.value}
                image={image?.value[0]}
                caption={caption}
                key={id}
                leftSvg={svg_icon?.value}
                counterStartValue={counter_start_value?.value}
                counterEndValue={counter_end_value?.value}
                isAnimated={animated_counter?.value[0]?.codename === "yes"}
                specialCharacter={special_character?.value[0]?.name}
                baseClassName="highlights"
                inView={inView}
              />
            )
          })}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HighlightGroupFragment on kontent_item_highlights_group {
    system {
      type
    }
    id
    elements {
      eyebrow_text {
        value
      }
      heading {
        value
      }
      sub_heading {
        value
      }
      alt_background_color {
        value {
          codename
        }
      }
      highlights {
        modular_content {
          ...HighlightFragment
        }
      }
    }
  }
`
