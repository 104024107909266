import React from "react"

interface HiglightsIconProps {
  leftSvg: string
  className: string
}

export const HighlightIcon = ({ leftSvg, className }: HiglightsIconProps) => {
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: leftSvg }}
      contentEditable="false"
    />
  )
}
