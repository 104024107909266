import React from "react"
import { graphql } from "gatsby"
import { MultiContentBlock } from "./MultiContentBlock"
import { HighlightsGroup } from "components/highlights/highlights-types"
import { MultiContentHighlights } from "components/highlights/MCB/MultiContentHighlights"
import { SingleContentBlock } from "./multi-content-block"
import "./multi-content-blocks.module.scss"

interface MultiContentBlocksProps {
  contentBlock: Array<SingleContentBlock | HighlightsGroup>
  heading?: string
  subHeading?: string
  layoutOverrideOptions?: string
  hasAltBackgroundColor: boolean
}

export const MultiContentBlocks = ({
  heading,
  subHeading,
  contentBlock,
  layoutOverrideOptions = "",
  hasAltBackgroundColor,
}: MultiContentBlocksProps) => {
  const getMultiContentBlockClassNameByBlockCount = (
    input: number | string
  ) => {
    switch (input) {
      case 1:
      case "n1_column":
        return "multi-content-block-wrapper-1-block"
      case 2:
      case "n2_columns":
        return "multi-content-block-wrapper-2-blocks"
      case 4:
      case "n4_columns":
        return "multi-content-block-wrapper-4-blocks"
      default:
        return "multi-content-block-wrapper-3-blocks"
    }
  }

  const contentBlocks = contentBlock?.filter(
    component =>
      component?.system?.type === "content_block" ||
      component?.system?.type === "highlights_group"
  )

  const alternateBackground = hasAltBackgroundColor
    ? " alternate-background-color"
    : ""

  const blockCount = contentBlocks.length

  const multiContentBlockClassName = layoutOverrideOptions
    ? getMultiContentBlockClassNameByBlockCount(layoutOverrideOptions)
    : getMultiContentBlockClassNameByBlockCount(blockCount)

  return (
    <section
      className={`multi-content-blocks u-content-block-padding u-content-block-box ${alternateBackground}`}
    >
      <div className="fr-container">
        {heading && (
          <div className="u-container-header">
            <h2>{heading}</h2>
          </div>
        )}
        {subHeading && (
          <p className="multi-content-blocks__introductory-paragraph">
            {subHeading}
          </p>
        )}
        <div className={multiContentBlockClassName}>
          {contentBlocks.map(({ id, elements, system }) => {
            if (!elements) return

            if (system?.type === "highlights_group") {
              const highlightGroup = elements as HighlightsGroup["elements"]
              const highlights = highlightGroup?.highlights?.modular_content!

              if (highlights?.length > 0) {
                return (
                  <MultiContentHighlights key={id} highlights={highlights} />
                )
              }
            }
            if (system?.type === "content_block") {
              const contentBlock = elements as SingleContentBlock["elements"]

              if (contentBlock && Object.keys(contentBlock).length > 0) {
                return (
                  <MultiContentBlock key={id} contentBlock={contentBlock} />
                )
              }
            }
          })}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment MultiContentBlocksFragment on kontent_item_multi_content_block {
    id
    elements {
      multi_block_heading {
        value
      }
      multi_content_background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      sub_heading {
        value
      }
      layout_override_options {
        value {
          codename
        }
      }
      alt_background_color {
        value {
          codename
        }
      }
      content_block {
        modular_content {
          ...ContentBlockFragment
          ...HighlightGroupFragment
        }
      }
    }
  }
`
